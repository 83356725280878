import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import backgroundImage from "../../assets/Logos/spotify.jpg";
import Box from "@mui/material/Box";

const YourComponent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Function to update windowWidth when the window size changes
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const containerStyle = {
    marginTop: "8vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "100vh",
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
  };

  const contentContainerStyle = {
    display: "flex",
    flexDirection: "column", // Change to column for mobile
    alignItems: "center", // Center content horizontally
    maxWidth: "1500px",
    width: "100%",
  };

  const textContainerStyle = {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    color: "white",
  };

  const h4Style = {
    fontSize: "2rem",
    marginBottom: "20px",
  };

  const body1Style = {
    fontSize: "1rem",
    lineHeight: "1.4",
  };

  const spotifyPlayerStyle = {
    padding: "20px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0)",
    width: windowWidth <= 768 ? "100%" : "80%", // Adjust the width based on window size
  };

  const subscribeBox = {
    color:"white",
    borderRadius:"4px",
    textAlign:"center",
    backgroundColor:"rgba(0, 0, 0, 0.4)",
    padding:"15px",
  }

  const iframeStyle = {
    width: "100%",
    height: "500px",
  };

  return (
    <div style={containerStyle} className="container">
      <div style={contentContainerStyle} className="content-container">
      <div style={textContainerStyle} className="text-container">
          <Box sx = {subscribeBox}>
            <Typography variant="h4" style={h4Style}>
              Subscribe To Our Playlist
            </Typography>
            <Typography variant="body1" style={body1Style}>
              and listen to all our epic deep house songs from Alternative Records.
            </Typography>
          </Box>
        </div>
        <div style={spotifyPlayerStyle} className="spotify-player">
          <iframe 
            src="https://open.spotify.com/embed/playlist/03ry66fHdViXCAl5z8vsEE?utm_source=generator&theme=0" 
            width="100%" 
            height="100%" 
            frameBorder="0" 
            allowfullscreen
            allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
            loading="lazy"
            title="Spotify Playlist"
            style={iframeStyle}
            ></iframe>
        </div>
       
      </div>
    </div>
  );
};

export default YourComponent;
