import Subscribe from "./Subscribe";
import MainVideo from "./video/MainVideo";
import HearFromYou from "../global/HearFromYou";

import Spotify from "../global/Spotify";
import React from "react";

function Home() {
  return (
    <div className="home" style={{    
      background: 'rgba(0, 0, 0, 0.05)',
    }}>
      <MainVideo/>
      <HearFromYou/>
      <Spotify/>
      <Subscribe />
    </div>
  );
}

export default React.memo(Home);
