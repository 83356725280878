import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import singer from "../../assets/Logos/ImageAndText.jpg";

const ImageAndText = () => {
    const isMobile = window.innerWidth <= 768;

  const [imageDimensions, setImageDimensions] = useState({
    width: "100%",
    height: "auto",
    left: 0
  });

  useEffect(() => {
    const handleResize = () => {
      setImageDimensions(calculateImageDimensions());
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Initialize the image dimensions
    setImageDimensions(calculateImageDimensions());

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateImageDimensions = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const imageAspectRatio = 1.5; // Adjust this as needed for your image
    const shrinkThreshold = 0.8; // Set the threshold at 80% of the viewport width

    // Calculate the maximum image width as a percentage of the viewport width
    const maxWidth = (viewportHeight / imageAspectRatio) * 1.5;

    // Calculate the corresponding height to maintain aspect ratio
    const maxHeight = maxWidth / imageAspectRatio;

    // Check if the image should start shrinking
    if (viewportWidth < maxWidth / shrinkThreshold) {
      return {
        width: `${(viewportWidth / viewportWidth) * 80}%`,
        height: `${(viewportWidth / imageAspectRatio / viewportHeight) * 100}%`,
        left: 0,
        margin: "10%"
      };
    }

    return {
      width: `${(maxWidth / viewportWidth) * 80}%`,
      height: `${(maxHeight / viewportHeight) * 100}%`,
      left: 0,
      margin: "5%",
      transform: "translateX(20%)" // Adjust the translateX value to move it to the right

    };
  };


  const textOverlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "3% !important", // Adjusted padding
    borderRadius: "4px",
  };
  

  // Define the button styles
  const buttonStyles = {
    backgroundColor: "white",
    padding: "calc(0.5vw + 10px) calc(1vw + 10px)",
    borderRadius: "4px",
    color: "black",
    textDecoration: "none",
    fontSize: isMobile ? "calc(1.5vw + 2px)" : "calc(0.7vw + 2px)",
    marginTop: "20px",
    textAlign: "center",
    margin: isMobile ? "0 auto" : 0,
    transition: "background-color 0.3s", // Add a transition for smooth hover effect
  };

  // Define the hover styles
  const hoverStyles = {
    backgroundColor: "lightgray", // Change the background color on hover
    color: "black", // Change the text color on hover
  };
  return (
    <Box position="relative" width="100%" overflow="hidden">
      <img
        id="image"
        alt="Image"
        src={singer}
        style={imageDimensions}
      />

    <Box
          p="2%"

          bgcolor="black"
          color="white"
          width={isMobile ? "80%" : "40%"}
          padding= "5%" // Adjusted padding for mobile and desktop
          display="flex"
          flexDirection="column"
          alignItems={isMobile ?"center" : "auto"}
          maxWidth={isMobile ?"100%":"auto"}
          minWidth={isMobile ? "auto": "200px"}
          marginInlineEnd={isMobile ?"0%":"auto"}
          zIndex={isMobile ?"1": "auto"}
          position={isMobile ?"relative" : "absolute"}
          sx={isMobile ? textOverlayStyle : {}}
          left= {isMobile ?"auto":"45%"}
          top={isMobile ?"auto":"30%"}
          borderRadius='4px'
        >
        <Box
          sx={{
            overflow: "auto",
            maxHeight: "100%",
            minHeight: "100px"
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: "calc(2.5vw + 16px)",
              fontWeight: "bold",
              mb: "20px"
            }}
          >
            Do you want to be heard?
          </Typography>
          <Typography
            variant="body1"
            sx={{
                fontSize: isMobile ? "calc(1.5vw + 2px)" : "calc(0.7vw + 2px)",
                mb: "20px",
              lineHeight: "1.4"
            }}
          >
           Over 40,000 songs are introduced to Spotify's vast music library every single day. In this immensely competitive landscape, how does an aspiring artist set themselves apart and capture the audience's attention? That's where we come in – to provide you with the solutions.          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: isMobile ? "calc(1.5vw + 2px)" : "calc(0.7vw + 2px)",
              mb: "20px",
              lineHeight: "1.4"
            }}
          >
            Are you brimming with an unquenchable passion for music? Fantastic, because we share that same fervor! Could you be the star we've been searching for?
          </Typography>
        </Box>
        <a
          href="/artist"
          style={buttonStyles}
        onMouseEnter={(e) => {
          // Apply hover styles on mouse enter
          e.target.style.backgroundColor = hoverStyles.backgroundColor;
          e.target.style.color = hoverStyles.color;
        }}
        onMouseLeave={(e) => {
          // Restore the default styles on mouse leave
          e.target.style.backgroundColor = buttonStyles.backgroundColor;
          e.target.style.color = buttonStyles.color;
        }}
          
        >
          Learn More
        </a>
      </Box>
    </Box>
  );
};

export default ImageAndText;
