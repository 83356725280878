import { Box, InputBase, Typography, IconButton, Button, Divider } from "@mui/material";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import { useState, useRef } from "react";
import emailjs from 'emailjs-com';
import { useNavigate } from 'react-router-dom';

const Subscribe = () => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  const inputStyle = {
    ml: 1,
    flex: 1,
    width: isMobile ? '100%' : '80%',
    padding: '10px',
    margin: '10px 0',
    border: 'none',
    borderRadius: '4px',
    background: "white",
  };

  const buttonStyle = {
    padding: '14px',
    background: 'white',
    color: 'black',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    fontWeight: 'bold',
  };

  const subscribeNewsletter = {
    width:"80%",
    maxWidth:"800px",
    margin:"0 auto",
    textAlign:"center",
    padding:"20px"
  }

  const formRef = useRef();

  const sendSubscriptionEmail = async (e) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    try {
      setIsSubmitting(true);
      await emailjs.sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      setEmail("");
      navigate('/subscription-success');
    } catch (error) {
      navigate('/subscription-failure');
    } finally {
      setIsSubmitting(false);

      if (formRef.current) {
        formRef.current.reset();
      }
    }
  };

  return (
    <Box  sx={subscribeNewsletter}>
      <IconButton>
        <MarkEmailReadOutlinedIcon fontSize="large" />
      </IconButton>
      <Typography variant="h3">Subscribe To Our Newsletter</Typography>
      <Typography>
        and receive preview & early access to our latest dance music & merch!
      </Typography>

      <Box
        p="20px 4px" // Padding added
        m="15px auto"
        display="flex"
        alignItems="center"
        width="100%"
        background="white"
      >
        <form ref={formRef} onSubmit={sendSubscriptionEmail} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <InputBase
            sx={inputStyle}
            placeholder="Enter email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            type="email"
            name="email"
            required
          />
          <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
          <Button type="submit" sx={buttonStyle} disabled={isSubmitting}>
            {isSubmitting ? "Submitting..." : "Subscribe"}
          </Button>
        </form>
      </Box>
      <div style={{ color: 'transparent' }}> | </div>

    </Box>
  );
};

export default Subscribe;
