import React from 'react';
import dj from "../../assets/Logos/pianoyellow.jpg";
import { useNavigate } from 'react-router-dom';

const NotFound = () => {
  const navigate = useNavigate(); // Get the navigate function

  const backgroundStyle = {
    backgroundImage: `url(${dj})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
  };

  const messageStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '30px',
    borderRadius: '10px',
  };

  const handleReloadClick = () => {
    // Use navigate to go back to the "/contact" route
    navigate('/');
  };

  return (
    <div style={backgroundStyle}>
      <div className="container">
        <div style={messageStyle}>
          <h1>Oops, something went wrong!</h1>
          <p>We're sorry, but the page was not found.</p>
          <p>Please try again later or contact our support team for assistance.</p>
          <button onClick={handleReloadClick}>Reload Page</button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
