import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import videoBg from "../../../assets/videoBg.mp4";
import alternative from "../../../assets/Logos/AlternativeTrans.png";
import ImageAndText from "../../global/ImageAndText";

const MainVideo = () => {
  const [logoSize, setLogoSize] = useState(500); // Initial logo size (width)
  const [logoTop, setLogoTop] = useState("50%"); // Initial logo top position

  const imageAndTextRef = useRef(null);

  const handleDiscoverMoreClick = () => {
    // Scroll down to the ImageAndText component using smooth scroll
    if (imageAndTextRef.current) {
      imageAndTextRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  
  const handleResize = () => {
    // Calculate the new logo size based on the video's width
    const videoWidth = window.innerWidth;
    const newLogoSize = videoWidth < 750 ? videoWidth /1.5 : 500; // Adjust the size based on the window width
    setLogoSize(newLogoSize);

    // Adjust logo top position based on window width
    const logoTopPosition = videoWidth < 1330 ? "25%" : "50%";
    setLogoTop(logoTopPosition);
  };

  useEffect(() => {
    // Add a window resize event listener to handle logo resizing
    window.addEventListener("resize", handleResize);

    // Initialize logo size and top position based on the initial window width
    handleResize();

    return () => {
      // Remove the event listener when the component unmounts
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Box>
    <Box key={`carousel-video`} position="relative">
    <div style={{
      position: 'relative',
      width: '100%',
      height: '700px',
    }}>
      <video
        src={videoBg}
        autoPlay
        loop
        muted
        style={{
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
        loading="lazy"
        preload="auto"
      />
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(0, 0, 0, 0.2)',
        }}
      />
    </div>


      <img
        src={alternative}
        alt="Alternative."
        style={{
          width: `${logoSize}px`,
          height: "auto",
          position: "absolute",
          top: logoTop,
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
        loading="lazy"
      />


      <Box
        color="white"
        padding="20px"
        borderRadius="4px"
        textAlign="left"
        backgroundColor="rgba(0, 0, 0, 0.4)"
        position="absolute"
        top="46%"
        left="10%"
        maxWidth="300px"
      >
        
        <Typography variant="h2">Let's create something amazing together!</Typography>
        <Typography
          fontWeight="bold"
          color="rgba(255, 255, 255, 0.9)"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
          onClick={handleDiscoverMoreClick}
        >
          Discover More
        </Typography>
        
        {/* Additional text under the "Summer Sale" box */}
        <Typography color="rgba(255, 255, 255, 0.7)">
         We're a music-loving record label dedicated to making your music heard. Our specialty is music distribution, and we're all about inclusion. We can't wait to hear from you!        
         </Typography>
      </Box>
    </Box>
     {/* Place a ref on the ImageAndText component */}
     <div ref={imageAndTextRef}>
     <ImageAndText />
   </div>
   </Box>

  );
};

export default React.memo(MainVideo);
