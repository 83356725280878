import React from "react";
import "./YouAsAnArtist.css"; // Replace ".css" with the actual file extension if needed
import people from "../../assets/Logos/concert.jpg";

import mixer from "../../assets/Logos/mixer.jpg";
import concert from "../../assets/Logos/concert2.jpg";
import studio from "../../assets/Logos/studio1.jpg";

const YouAsAnArtist = () => {
  return (
    <section className="page-section" id="about">
      <div className="container">
        <div className="text-center">
          <h2 className="section-heading text-uppercase">What does it mean to be part of the Alternative Family?</h2>
          <h3 className="section-subheading text-muted">You belong here just as much as anyone else.</h3>
        </div>
        <ul className="timeline">
          <li style={{ backgroundImage: { people } }}>
            <div className="timeline-image"><img className="rounded-circle img-fluid" src={ people } alt="..." /></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h1 className="subheading">Devotion</h1>
              </div>
              <div className="timeline-body"><p className="text-muted">At Alternative Records, we are deeply dedicated to music. Each of us, within our team, is not only involved in running the label but also pursuing our own music projects.</p></div>
            </div>
          </li>
          <li className="timeline-inverted" style={{ backgroundImage: { people } }}>
            <div className="timeline-image"><img className="rounded-circle img-fluid" src={ mixer } alt="..." /></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h1 className="subheading">Distribution</h1>
              </div>
              <div className="timeline-body"><p className="text-muted">We understand the immense effort and passion you pour into your music. That's why we're committed to ensuring your songs receive the recognition they deserve. Alternative Records boasts an extensive network of Spotify playlists, continually expanding to amplify your music's reach.</p></div>
            </div>
          </li>
          <li style={{ backgroundImage: { people } }}>
            <div className="timeline-image"><img className="rounded-circle img-fluid" src={ concert } alt="..." /></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h1 className="subheading">Growth</h1>
              </div>
              <div className="timeline-body"><p className="text-muted">We're an artist-centric label, guided by the mantra "By Artists For Artists." This means we'll go the extra mile to provide your project with the time and devotion it requires. In a complex industry, we prioritize transparency, offering insights and knowledge to help you navigate effectively. Say goodbye to lengthy, restrictive contracts; we tailor agreements to fit you and your project.</p></div>
            </div>
          </li>
          <li className="timeline-inverted" style={{ backgroundImage: { people } }}>
            <div className="timeline-image"><img className="rounded-circle img-fluid" src={ studio } alt="..." /></div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <h1 className="subheading">Network</h1>
              </div>
              <div className="timeline-body"><p className="text-muted">Our mission is to elevate you to the next level and cultivate a nurturing environment where you can flourish, connect with kindred spirits, and foster collaborations. Welcome to Alternative Records, your musical home for growth and networking.</p></div>
            </div>
          </li>
          <li>
            <div className="timeline-image">
              <h4>
                Be Part
                <br />
                Of Our
                <br />
                Story!
              </h4>
            </div>
            <div className="timeline-panel">
              <div className="timeline-heading">
                <a href="/contact" className="subheading white-link">
                  <h1>Send Your Demo</h1>
                </a>
              </div>
              <div className="timeline-body"><p className="text-muted">Start by sending us your awesome demo, and we'll be in touch.</p></div>
            </div>
          </li>
        </ul>
      </div>
    </section>
  );
};

export default YouAsAnArtist;
