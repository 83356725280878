import React, { useState } from "react";
import { Box, IconButton } from "@mui/material";
import {
  MenuOutlined,
} from "@mui/icons-material";
import { useNavigate, useLocation } from "react-router-dom"; // Import useLocation
import facebook from "../../assets/Logos/facebookT.png";
import instagram from "../../assets/Logos/instagramT.png";
import youtube from "../../assets/Logos/youtubeT.png";
import spotify from "../../assets/Logos/spotifyT.png";
import alternative from "../../assets/Logos/AlternativeTr.png";

function Navbar() {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation to get the current location
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMobileMenu = () => {
    setMobileMenuVisible(!mobileMenuVisible);
  };

  // Define a dummy function
  const dummyFunction = () => {
    setMobileMenuVisible(false);

    // Check if the current location is the "contact" page
    if (location.pathname === "/contact") {
      // Reload the page to scroll to the top
      window.location.reload();
    }
  };

  // Calculate the top position for the mobile menu based on its visibility
  const mobileMenuTop = mobileMenuVisible ? "60px" : "-100px";

  return (
    <Box
      display="flex"
      alignItems="center"
      width="100%"
      height="60px"
      backgroundColor="rgba(0, 0, 0, 0.95)"
      color="white"
      position="fixed"
      top="0"
      left="0"
      zIndex="100" /* Set a higher z-index for the navbar */
    >
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          onClick={() => {
            navigate("/");
            dummyFunction(); // Call the dummy function
          }}
          sx={{ "&:hover": { cursor: "pointer" } }}
        >
          <img
            src={alternative}
            alt="Alternative."
            style={{ width: "130px", height: "auto" }}
          />
        </Box>

        {/* Desktop Text and Icons */}
        <Box
          display={{ xs: "none", md: "flex" }}
          alignItems="center"
          columnGap="0px"
        >
          <Box
            onClick={() => {
              navigate("/artist");
              dummyFunction(); // Call the dummy function
            }}
            sx={{ "&:hover": { cursor: "pointer" } }}
            padding={"10px"}
          >
            You as an Artist
          </Box>
          {/*
          <Box
            onClick={() => {
              navigate("/");
              dummyFunction(); // Call the dummy function
            }}
            sx={{ "&:hover": { cursor: "pointer" } }}
            padding={"10px"}
          >
            The Artist
          </Box>
          */}
          <Box
            onClick={() => {
              navigate("/label");
              dummyFunction(); // Call the dummy function
            }}
            sx={{ "&:hover": { cursor: "pointer" } }}
            padding={"10px"}
          >
            The Label
          </Box>
          <Box
            onClick={() => {
              navigate("/contact");
              dummyFunction(); // Call the dummy function
            }}
            sx={{ "&:hover": { cursor: "pointer" } }}
            padding={"10px"}
          >
            Contact
          </Box>
          <IconButton sx={{ color: "white" }}>
            <a
              href="https://www.facebook.com/profile.php?id=100081792046105"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={facebook}
                alt="Facebook"
                style={{ width: "30px", height: "auto" }}
              />
            </a>
          </IconButton>
          <IconButton sx={{ color: "white" }}>
            <a
              href="https://www.instagram.com/alternativerecordsofficial/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagram}
                alt="Instagram"
                style={{ width: "30px", height: "auto" }}
              />
            </a>
          </IconButton>
          <IconButton sx={{ color: "white" }}>
            <a
              href="https://www.youtube.com/channel/UCZnpeRNwuTMR9sdTVgZWe-w"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={youtube}
                alt="Youtube"
                style={{ width: "30px", height: "auto" }}
              />
            </a>
          </IconButton>
          <IconButton sx={{ color: "white" }}>
            <a
              href="https://open.spotify.com/user/31lqcbcjoim43pscnwmrlljopcxm?si=4615ef5a03844081"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={spotify}
                alt="Spotify"
                style={{ width: "30px", height: "auto" }}
              />
            </a>
          </IconButton>
        </Box>

        <Box
          display={{ xs: mobileMenuVisible ? "block" : "none", md: "none" }}
          flexDirection="column"
          alignItems="center"
          position="absolute"
          top={mobileMenuTop} // Use the dynamic top position
          left="0"
          backgroundColor="rgba(0, 0, 0, 0.95)"
          width="100%"
          zIndex="3"
          padding="10px"
          transition="top 0.5s ease-in-out" // Adjusted transition property
        >
          <Box
            onClick={() => {
              navigate("/artist");
              dummyFunction(); // Call the dummy function
            }}
            sx={{
              "&:hover": { cursor: "pointer" },
              textAlign: "center", // Center-align text
              color: "white",
              padding: "15px",
            }}
          >
            You as an Artist
          </Box>
                    {/*

          <Box
            onClick={() => {
              navigate("/");
              dummyFunction(); // Call the dummy function
            }}
            sx={{
              "&:hover": { cursor: "pointer" },
              textAlign: "center", // Center-align text
              color: "white",
              padding: "15px",
            }}
          >
            The Artist
          </Box>
                    */}

          <Box
            onClick={() => {
              navigate("/label");
              dummyFunction(); // Call the dummy function
            }}
            sx={{
              "&:hover": { cursor: "pointer" },
              textAlign: "center", // Center-align text
              color: "white",
              padding: "15px",
            }}
          >
            The Label
          </Box>
          <Box
            onClick={() => {
              navigate("/contact");
              dummyFunction(); // Call the dummy function
            }}
            sx={{
              "&:hover": { cursor: "pointer" },
              textAlign: "center", // Center-align text
              color: "white",
              padding: "15px",
            }}
          >
            Contact
          </Box>
          {/* Mobile Icons */}
          <Box display="flex" justifyContent="center" columnGap="15px">
            <IconButton sx={{ color: "white" }}>
              <a
                href="https://www.facebook.com/profile.php?id=100081792046105"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={facebook}
                  alt="Facebook"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
            </IconButton>
            <IconButton sx={{ color: "white" }}>
              <a
                href="https://www.instagram.com/alternativerecordsofficial/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={instagram}
                  alt="Instagram"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
            </IconButton>
            <IconButton sx={{ color: "white" }}>
              <a
                href="https://www.youtube.com/channel/UCZnpeRNwuTMR9sdTVgZWe-w"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={youtube}
                  alt="Youtube"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
            </IconButton>
            <IconButton sx={{ color: "white" }}>
              <a
                href="https://open.spotify.com/user/31lqcbcjoim43pscnwmrlljopcxm?si=4615ef5a03844081"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={spotify}
                  alt="Spotify"
                  style={{ width: "30px", height: "auto" }}
                />
              </a>
            </IconButton>
          </Box>
        </Box>

        {/* Hamburger Menu Icon */}
        <IconButton
          onClick={() => {
            toggleMobileMenu();
          }}
          sx={{ color: "white", display: { xs: "block", md: "none" } }}
        >
          <MenuOutlined />
        </IconButton>
      </Box>
    </Box>
  );
}

export default Navbar;
