import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import alternative from "../../assets/Logos/AlternativeTr.png";
import { useNavigate } from "react-router-dom";

function Footer() {
  const navigate = useNavigate();
  const spacing = "15px"; // Define the spacing variable here

  return (
    <Box
      padding="40px 0"
      backgroundColor="black"
      textAlign="center" // Center-align content for mobile
    >
      <Box
        width="80%"
        margin="auto"
        display="flex"
        justifyContent="space-between"
        alignItems="center" // Center content vertically for mobile
        flexWrap="wrap" // Remove flexWrap from here
        rowGap={spacing} // Use the spacing variable
      >
        <Box width="100%" maxWidth="300px" mb={spacing} margin="auto"> {/* Center horizontally for mobile */}
          <Box
            onClick={() => navigate("/")}
            sx={{ "&:hover": { cursor: "pointer" } }}
          >
            <img
              src={alternative}
              alt="Alternative."
              style={{ width: "200px", height: "auto" }}
            />
          </Box>
          <Typography variant="body1" color="white" mt={spacing}>
            Introducing Alternative Records, your destination for captivating dance
            music. Our passion for music drives us to curate unique
            pieces of music that embody the spirit of the modern era. From timeless
            classics to cutting-edge flow, each piece is a canvas for
            self-expression. Join us in embracing the essence of the "new age"
            as we redefine dance music.
          </Typography>
        </Box>

 

        <Box width="100%" maxWidth="300px" mb={spacing} margin="auto">
          {/* Center horizontally for mobile */}
          <Typography variant="h4" fontWeight="bold" color="white"mb={spacing}>
            Social Medias
          </Typography>
          <Typography variant="body1" color="white" >
            <a href="https://open.spotify.com/user/313zn3ibl3z6llqou24jbd7hz5we?si=13e597fc130841df" style={{ color: 'white' }}>
              Spotify
            </a>
          </Typography>
          <Typography variant="body1" color="white">
            <a href="https://www.youtube.com/channel/UCZnpeRNwuTMR9sdTVgZWe-w" style={{ color: 'white' }}>
              Youtube
            </a>
          </Typography>
          <Typography variant="body1" color="white">
            <a href="https://www.facebook.com/profile.php?id=100081792046105" style={{ color: 'white' }}>
              Facebook
            </a>
          </Typography>
          <Typography variant="body1" color="white">
            <a href="https://www.instagram.com/alternativerecordsofficial/" style={{ color: 'white' }}>
              Instagram
            </a>
          </Typography>
        </Box>

        <Box width="100%" maxWidth="300px" mb={spacing} margin="auto"> {/* Center horizontally for mobile */}
          <Typography variant="h4" fontWeight="bold" color="white" mb={spacing}>
            Contact Us
          </Typography>
          <Typography variant="body1" color="white">
            Völundsgatan 10 Stockholm, SE 15145
          </Typography>
          <Typography variant="body1" sx={{ wordWrap: "break-word" }} color="white">
            info@thealternativerecords.com
          </Typography>
          <Typography variant="body1" sx={{ wordWrap: "break-word" }} color="white">
            contact@thealternativerecords.com
          </Typography>
          <Typography variant="body1" color="white">
            support@thealternativerecords.com
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
