import { useEffect } from "react";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./scenes/home/Home";
import Contact from "./scenes/home/Contact";
import ContactSuccess from "./scenes/home/ContactSuccess";
import FailurePage from './scenes/home/ContactFailure'; 
import NotFound from './scenes/global/NotFound';
import Label from './scenes/label/Label'; 
import SubscriptionSuccess from './scenes/home/SubscribeSuccess'

import Artist from "./scenes/artist/YouAsAnArtist";
import Navbar from "./scenes/global/Navbar";
import Footer from "./scenes/global/Footer";
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/artist" element={<Artist />} />
          <Route path="/label" element={<Label/>} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/contact-success" element={<ContactSuccess/>} />
          <Route path="/contact-failure" element={<FailurePage />} />
          <Route path="/subscription-success" element={<SubscriptionSuccess/>} />
          <Route path="*" element={<NotFound/>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
