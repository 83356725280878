import React from 'react';
import dj from "../../assets/Logos/concertConfetti.jpg";
import alternative from "../../assets/Logos/AlternativeTrans.png";

const Contact = () => {
  const backgroundStyle = {
    backgroundImage: `url(${dj})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
  };

  const formStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '30px',
    borderRadius: '10px',
  };

  const linkStyle = {
    color: 'white', // Set the hyperlink color to white
    textDecoration: 'none', // Remove underline
    fontWeight: 'bold', // Make it bold (optional)
    borderBottom: '1px solid white', // Add an underline
  };

  return (
    <div style={backgroundStyle}>
      <div className="container">
        <form style={formStyle}>
          <img
            src={alternative}
            alt="Alternative."
            style={{ width: '50%', height: 'auto' }}
          />
          <h1>Thanks for your Demo</h1>
          <p>
            While waiting, head over to our{' '}
            <a
              href="https://www.youtube.com/channel/UCZnpeRNwuTMR9sdTVgZWe-w"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Youtube
            </a>{' '}
            or{' '}
            <a
              href="https://open.spotify.com/user/313zn3ibl3z6llqou24jbd7hz5we?si=13e597fc130841df"
              target="_blank"
              rel="noopener noreferrer"
              style={linkStyle}
            >
              Spotify
            </a>{' '}
            for epic dance music. We'll get back to you as soon as possible.
          </p>
        </form>
      </div>
    </div>
  );
};

export default Contact;
