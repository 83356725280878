import Contact from "../../components/contact/Contact";

function Home() {
  return (
    <div className="home">
      <Contact/>
    </div>
  );
}

export default Home;
