import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import dj from "../../assets/Logos/concert-dj.jpg";
import alternative from "../../assets/Logos/AlternativeTrans.png";
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const navigate = useNavigate();
  const isMobile = window.innerWidth <= 768;

  const [secrets] = useState({
    REACT_APP_EMAILJS_SERVICE_ID: process.env.REACT_APP_EMAILJS_SERVICE_ID,
    REACT_APP_EMAILJS_TEMPLATE_ID: process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
    REACT_APP_EMAILJS_PUBLIC_KEY: process.env.REACT_APP_EMAILJS_PUBLIC_KEY
  });

  const sendEmail = async (e) => {
    e.preventDefault();

    try {
      await emailjs.sendForm(
        secrets.REACT_APP_EMAILJS_SERVICE_ID,
        secrets.REACT_APP_EMAILJS_TEMPLATE_ID,
        e.target,
        secrets.REACT_APP_EMAILJS_PUBLIC_KEY
      );

      e.target.reset();

      navigate('/contact-success');
    } catch (error) {
      navigate('/contact-failure');
    }
  };

  const backgroundStyle = {
    backgroundImage: `url(${dj})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    textAlign: 'center',
    padding: '20px',
  };

  const containerStyle = {
    marginTop: '50px',
    overflow: 'auto'
  };

  const formStyle = {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    padding: '30px',
    borderRadius: '10px',
  };

  const inputStyle = {
    width: isMobile ? '100%' : '80%',
    padding: '10px',
    margin: '10px 0',
    border: 'none',
    borderRadius: 'px',
  };

  const sendDemoTextStyle = {
    fontSize: isMobile ? '1.4rem' : '2rem',
  };

  const buttonStyles = {
    backgroundColor: "white",
    border: "1px solid black",
    padding: "calc(0.5vw + 10px) calc(1vw + 10px)",
    borderRadius: "4px",
    color: "black",
    textDecoration: "none",
    width: isMobile ? "45%" : "25%",
    marginTop: "20px",
    textAlign: "center",
    margin: "0 auto",
    transition: "background-color 0.3s",
  };

  const hoverStyles = {
    backgroundColor: "lightgray",
    color: "black",
  };

  return (
    <div style={backgroundStyle}>
      <div className="container" style={containerStyle}>
        <form onSubmit={sendEmail} style={formStyle}>
          <img
            src={alternative}
            alt="Alternative."
            style={{ width: '50%' }}
          />
          <h1 style={sendDemoTextStyle}>Send us your Demo</h1>
          <p>Got a demo? We'd love to hear from you. Send us a message with a link to your demo, and we'll get back to you as soon as possible.</p>

          <input type="text" style={inputStyle} placeholder="Name" name="name" required />
          <input type="email" style={inputStyle} placeholder="Email Address" name="email" required />
          <input type="text" style={inputStyle} placeholder="Artist Name" name="subject" required />
          <textarea
            style={{ ...inputStyle, minHeight: '120px' }}
            placeholder="Your message"
            name="message"
            required
          ></textarea>

          <button
            type="submit"
            style={buttonStyles}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = hoverStyles.backgroundColor;
              e.target.style.color = hoverStyles.color;
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = buttonStyles.backgroundColor;
              e.target.style.color = buttonStyles.color;
            }}
          >
            Send Demo
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
