import React from 'react';
import people from "../../assets/Logos/aboutus.jpg";
import alternative from "../../assets/Logos/AlternativeTr.png";

const alternativeRecordsStyles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Transparent black background
    padding: '20px',
    borderRadius: '10px',
    maxWidth: '800px',
    margin: '0 auto',
    color: '#fff', // White text color
  },
  heading: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  paragraph: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '20px',
  },
};

const bodyStyle = {
  marginTop: '60px',
  backgroundImage: `url(${people})`, // Use backticks to include the variable value
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  minHeight: '100vh', // Ensure the background covers the entire viewport
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'auto'
};

const AlternativeRecords = () => {
  return (
    <div style={bodyStyle}>
      <div style={alternativeRecordsStyles.container}>
      <img
            src={alternative}
            alt="Alternative."
            style={{ width: '50%' }} // Set width to 100% for responsiveness
          />
        <h1 style={alternativeRecordsStyles.heading}>Welcome to Alternative Records</h1>
        <p style={alternativeRecordsStyles.paragraph}>
          At Alternative Records, we are deeply committed to music. Each member
          of our team is passionately involved in music, whether it's working on
          our own projects or collaborating within the label.
        </p>

        <p style={alternativeRecordsStyles.paragraph}>
          We understand the immense time and love you invest in your songs, which
          is why we consistently strive to ensure your music gets the attention it
          truly deserves. Alternative Records boasts one of the world's largest
          networks of Spotify playlists, and we are continually expanding.
        </p>

        <p style={alternativeRecordsStyles.paragraph}>
          We take pride in being an artist-friendly label, guided by the ethos of
          "By Artists, For Artists." This means that we wholeheartedly dedicate
          ourselves to your projects, providing the time and attention they
          require. We recognize the challenges of navigating the music industry,
          and we're committed to transparency. We aim to assist you in achieving
          your full potential, sharing our experiences and knowledge with you.
          Forget about lengthy, frustrating contracts that bind you for years; we
          tailor agreements to match your unique needs and your project's vision.
        </p>

        <p style={alternativeRecordsStyles.paragraph}>
          Our aspiration is to elevate you to the next level and establish a
          creative haven where you can flourish, connect, and collaborate with
          kindred spirits.
        </p>

        <p style={alternativeRecordsStyles.paragraph}>
          Does this resonate with your vision? Don't hesitate—share your music
          with us today!
        </p>
      </div>
    </div>
  );
};

export default AlternativeRecords;
