import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import people from "../../assets/Logos/concert.jpg";

const ImageAndText = () => {
  const isMobile = window.innerWidth <= 768;

  const [imageDimensions, setImageDimensions] = useState({
    width: "100%",
    height: "auto",
    left: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      setImageDimensions(calculateImageDimensions());
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Initialize the image dimensions
    setImageDimensions(calculateImageDimensions());

    // Clean up the event listener on unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculateImageDimensions = () => {
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;
    const imageAspectRatio = 1.5; // Adjust this as needed for your image
    const shrinkThreshold = 0.8; // Set the threshold at 80% of the viewport width

    // Calculate the maximum image width as a percentage of the viewport width
    const maxWidth = isMobile ? viewportWidth : (viewportHeight / imageAspectRatio) * 1.5;

    // Calculate the corresponding height to maintain aspect ratio
    const maxHeight = maxWidth / imageAspectRatio;

    // Check if the image should start shrinking
    if (viewportWidth < maxWidth / shrinkThreshold) {
      return {
        width: `${(viewportWidth / viewportWidth) * 80}%`,
        height: `${(viewportWidth / imageAspectRatio / viewportHeight) * 100}%`,
        left: 0,
        margin: "5%", // Adjusted margin
      };
    }

    return {
      width: `${(maxWidth / viewportWidth) * 80}%`,
      height: `${(maxHeight / viewportHeight) * 100}%`,
      left: 0,
      margin: isMobile ? "5%" : "5%", // Adjusted margin for mobile and desktop
      transform: isMobile ? "translateX(0%)" : "translateX(20%)",
    };
  };

  const mobileContainerStyle = {
    flexDirection: "column",
    position: "relative",
  };

  const textOverlayStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    color: "white",
    padding: "3% !important", // Adjusted padding
    borderRadius: "4px",
  };


  // Define the button styles
  const buttonStyles = {
    backgroundColor: "white",
    borderRadius: "4px",
    color: "black",
    textDecoration: "none",
    fontSize: isMobile ? "calc(2vw + 2px)" : "calc(0.7vw + 2px)",
    padding: isMobile ? "10px 15px" : "calc(0.5vw + 10px) calc(1vw + 10px)",
    marginTop: isMobile ? "20px" : "20px", // Adjusted marginTop for both mobile and desktop
    alignSelf: isMobile ? "center" : "flex-start", // Center button for mobile, left for desktop
    margin: isMobile ? "0 auto" : "0", // Center button horizontally for mobile
    transition: "background-color 0.3s", // Add a transition for smooth hover effect
  };

  // Define the hover styles
  const hoverStyles = {
    backgroundColor: "lightgray", // Change the background color on hover
    color: "black", // Change the text color on hover
  };

  return (
    <Box
      backgroundColor="hsla(300, 2.439024390243891%, 91.96078431372548%, 1)"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="70vh"
      overflow="hidden"
    >
      <Box
        width="100%"
        display="flex"
        justifyContent="center"
        alignItems="center"
        maxWidth="1200px"
        position="relative"
        sx={isMobile ? mobileContainerStyle : {}}
      >
        <Box
          bgcolor="black"
          color="white"
          width={isMobile ? "80%" : "85%"}
          padding={isMobile ? "5%" : "2%"} // Adjusted padding for mobile and desktop
          display="flex"
          flexDirection="column"
          alignItems="center"
          maxWidth="100%"
          marginInlineEnd={isMobile ? "0%" : "-10%"}
          zIndex="1"
          position="relative"
          sx={isMobile ? textOverlayStyle : {}}
          borderRadius='4px'
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: isMobile ? "calc(6vw + 0px)" : "calc(2.5vw + 16px)",
              fontWeight: "bold",
              mb: "20px",
            }}
          >
            We want to hear from you
          </Typography>
          <Typography
            variant="body1"
            sx={{
              fontSize: isMobile ? "calc(2.5vw + 0px)" : "calc(0.7vw + 2px)",
              mb: "20px",
              lineHeight: "1.4",
            }}
          >
            You're under no commitment, pressure, or obligation; we genuinely relish the opportunity to listen to your demos.
          </Typography>
          <a
            href="/contact"
            style={{
                ...buttonStyles,
              }}
              onMouseEnter={(e) => {
                // Apply hover styles on mouse enter
                e.target.style.backgroundColor = hoverStyles.backgroundColor;
                e.target.style.color = hoverStyles.color;
              }}
              onMouseLeave={(e) => {
                // Restore the default styles on mouse leave
                e.target.style.backgroundColor = buttonStyles.backgroundColor;
                e.target.style.color = buttonStyles.color;
              }}
          >
            Send us your Demo
          </a>
        </Box>

        <Box
          padding={isMobile ? "10%" : "5%"}
          width={isMobile ? "100%" : "75%"}
          maxWidth="100%"
          position="relative"
          flexShrink="0"
          marginInlineStart={isMobile ? "0%" : "-10%"}
        >
          <img
            src={people}
            style={{ width: "100%", height: "100%" }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ImageAndText;
