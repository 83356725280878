import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCartOpen: false,
  cart: [],
  items: [],
};

export const selectTotalItemCount = (state) =>
  state.cart.cart.reduce((total, item) => total + item.count, 0);


export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    setItems: (state, action) => {
      state.items = action.payload;
    },

    addToCart: (state, action) => {
      const newItem = action.payload.item;
      const existingItem = state.cart.find(
        (cartItem) =>
          cartItem.id === newItem.id && cartItem.selectedSize === newItem.selectedSize
      );
    
      if (existingItem) {
        // Item with the same ID and size exists; update its quantity
        existingItem.count += newItem.count;
      } else {
        // Item does not exist; add it to the cart
        state.cart.push(newItem);
      }
    },

    removeFromCart: (state, action) => {
      const { id: removeItemId, selectedSize: removeItemSize } = action.payload;
      state.cart = state.cart.filter(
        (item) => item.id !== removeItemId || item.selectedSize !== removeItemSize
      );
    },

    increaseCount: (state, action) => {
      const { id, selectedSize } = action.payload;
      const itemToUpdate = state.cart.find(
        (item) => item.id === id && item.selectedSize === selectedSize
      );
      if (itemToUpdate) {
        itemToUpdate.count++;
      }
    },

    decreaseCount: (state, action) => {
      const { id, selectedSize } = action.payload;
      const itemToUpdate = state.cart.find(
        (item) => item.id === id && item.selectedSize === selectedSize
      );
      if (itemToUpdate && itemToUpdate.count > 1) {
        itemToUpdate.count--;
      }
    },

    setIsCartOpen: (state) => {
      state.isCartOpen = !state.isCartOpen;
    },
  },
});

export const {
  setItems,
  addToCart,
  removeFromCart,
  increaseCount,
  decreaseCount,
  setIsCartOpen,
} = cartSlice.actions;

export default cartSlice.reducer;
